import React, { useEffect, useState } from 'react';
import TableIssue from '../table-issue/table-issue';
import './security-issues-tables.scss';
import { CATEGORIES_CONFIG } from '../security-dashboard.constant';
import { ReactComponent as CheckListIcon } from '../../../../assets/svg/checklist_96.svg';
import SecurityIssueDetails from '../../security-issue-details/security-issue-details';
import { IssueDetail, TopCategoriesIssueDetail } from '../../../../utils/model';
import { Link } from 'react-router-dom';
import widgetsService from '../../../../services/widgets.service';
import { WIDGET_NAME_MAP } from '../../../../utils/widget-map';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import LoadingWrapper from '../../../shared/loading-wrapper/loading-wrapper';

const SecurityIssuesTables: React.FC = observer(() => {
    const { dashboardStore, customerStore } = useStores();

    const [selectedIssue, setSelectedIssue] = useState<IssueDetail | null>(null);
    const [data, setData] = useState<TopCategoriesIssueDetail | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if (customerStore.selectedCustomer) {
            widgetsService.getWidgetData(customerStore.selectedCustomer.name, WIDGET_NAME_MAP.SECURITY_ISSUES_TOP3, dashboardStore.dateRangeObj)
                .then((res) => {
                    setData(res[0]);
                    setIsLoading(false);
                })
                .catch(() => setIsLoading(false));
        }
    }, [dashboardStore.dateRangeObj, customerStore.selectedCustomer]);


    const handleRowClick = (row: IssueDetail) => {
        setSelectedIssue(row);
    };

    return <>
        <div className="security-issues-tables">
            <div className="security-issues-tables-header">
                <div>
                    <div className="security-issues-tables-title">Security Issues</div>
                    <Link className="security-view-all" to="../security-issue-management">View All</Link>
                </div>
                <div className="security-issues-tables-stats">
                    <LoadingWrapper isLoading={isLoading} skeletonProps={{ height: 40, width: 270 }}>
                        {data?.high_importance > 0 && <div className="high">
                            <span>High Importance</span>
                            <span>{data?.high_importance.toLocaleString('en-US')}</span>
                        </div>}
                        <div className="total">
                            <span>Total</span>
                            <span>{data?.total_issues.toLocaleString('en-US')}</span>
                        </div>
                    </LoadingWrapper>
                </div>
            </div>
            {data?.total_issues === 0 ? (
                <div className="empty-state-total">
                    <CheckListIcon />
                    <div className="empty-state-title">No Security Issues Detected</div>
                    <div className="empty-state-desc">Great news! Your system is currently free of any security issues.</div>
                    <div className="empty-state-info">Our continuous monitoring ensures that your data and operations remain safe and secure.</div>
                </div>
            ) : (
                <div className="tables-issues-wrapper">
                    {CATEGORIES_CONFIG.map(category => (
                        <TableIssue
                            isLoading={isLoading}
                            key={category.fieldName}
                            categoryKey={category.fieldName}
                            total={data?.table_data[category.fieldName]?.total_issues}
                            categoryName={category.displayName}
                            icon={<category.icon />}
                            data={data?.table_data[category.fieldName]?.rows || []}
                            color={category.color}
                            onRowClick={handleRowClick}
                        />
                    ))}
                </div>
            )}
        </div>
        {selectedIssue && (
            <SecurityIssueDetails
                issue={selectedIssue}
                onClose={() => setSelectedIssue(null)}
                customerName={customerStore.selectedCustomer.name}
                dateRangeObj={dashboardStore.dateRangeObj}
            />
        )}
    </>;
});

export default SecurityIssuesTables;
