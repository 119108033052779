import React, { useMemo } from 'react';
import LineChart, { ColorType } from '../line-chart/line-chart';
import { ReactComponent as UpArrow } from '../../../../assets/svg/trend-up-arrow.svg';
import { ReactComponent as DownArrow } from '../../../../assets/svg/trend-down-arrow.svg';
import './trend-chart.scss';
import LoadingWrapper from '../../../shared/loading-wrapper/loading-wrapper';

interface TrendChartProps {
    title?: string;
    desc?: string;
    percent: number;
    trendDirection: 'up' | 'down';
    trendColor: 'red' | 'green';
    points: { x: number; y: number }[];
    width?: number;
    height?: number;
    isLoading?: boolean;
}

const TrendChart: React.FC<TrendChartProps> = ({ title = "", desc = "", percent, trendDirection, trendColor, points, width, height, isLoading }) => {
    const statusIcon = trendDirection === 'up' ? <UpArrow /> : <DownArrow />;

    const lineColor = useMemo(() => {
        if (percent === 0) {
            return ColorType.GREY;
        } else {
            if (trendColor === 'red') {
                return ColorType.RED;
            }
            return ColorType.GREEN;
        }
    }, [trendColor, percent]);

    return (
        <div className="trend-chart">
            <div className="trend-chart-info">
                <div className="trend-chart-title">
                    <LoadingWrapper isLoading={isLoading} skeletonProps={{ height: 46, width: 200 }}>{title}</LoadingWrapper>
                </div>
                <div className="trend-chart-desc">
                    <LoadingWrapper isLoading={isLoading} skeletonProps={{ height: 18, width: 200 }}>{desc}</LoadingWrapper>
                </div>
            </div>
            <div className="trend-chart-line">
                <LoadingWrapper isLoading={isLoading} skeletonProps={{ className: 'chart-trend-line' }} >
                    <div className={`trend-chart-status ${trendColor}`}>
                        { percent > 0 ?
                            <><div className="trend-chart-percent">{percent}% {trendDirection}</div>{statusIcon}</> :
                            <div className="no-change">No change</div>
                        }
                    </div>
                    {points?.length > 0 && <LineChart points={points} colorType={lineColor} width={width} height={height} />}
                </LoadingWrapper>
            </div>
        </div>
    );
};

export default TrendChart;
