import React from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';

interface LoadingWrapperProps {
    isLoading: boolean;
    skeletonProps?: SkeletonProps;
    children: React.ReactNode;
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ isLoading, skeletonProps, children }) => {
    if (isLoading) {
        return <Skeleton {...skeletonProps} />;
    }
    return <>{children}</>;
};

export default LoadingWrapper;
