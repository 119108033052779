import React, { useMemo } from 'react';
import './affected-devices.scss';
import TrendChart from '../security-dashboard/trend-chart/trend-chart';
import { convertPointsToAxis } from '../../../utils/parse';

interface AffectedDevicesProps {
    points: any[];
    deviceNumber: number;
    percent: number;
    trendDirection: 'up' | 'down';
}

const AffectedDevices: React.FC<AffectedDevicesProps> = ({ points, deviceNumber, percent, trendDirection }) => {

    const CHART_HEIGHT = 90;

    const convertedPoint = useMemo(() =>
        convertPointsToAxis(points, CHART_HEIGHT), [points]);

    return (
        <div className="affected-devices">
            <div className="affected-devices-title">Affected Devices</div>
            <div className="device-number">{deviceNumber}</div>
           <TrendChart
                percent={percent}
                trendDirection={trendDirection}
                trendColor={trendDirection === 'up' ? 'red' : 'green'}
                points={convertedPoint}
                height={CHART_HEIGHT}
                width={190}
            />
        </div>
    );
};

export default AffectedDevices;
