import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { TrafficClass, TrafficClassRule } from '../../../../../dtos/traffic-classes.dto';
import { useStores } from '../../../../store';
import { ReactComponent as PencilIcon } from '../../../../../assets/svg/pencil.svg';
import { Button, Input } from 'antd';
import { validateIP, validatePort } from '../../../../../utils/util';
import isValidDomain from 'is-valid-domain';
import CustomModal from '../../../../shared/custom-modal/custom-modal';
import './traffic-destination-modal.scss';

export interface TrafficDestinationValues {
    destination: string;
    port: string;
}

export const initialValues = {
    destination: '',
    port: ''
}

interface AddEditTrafficDestinationProps {
    trafficClass: TrafficClass;
    rule?: TrafficClassRule;
    mode: 'add' | 'edit';
    disabled?: boolean;
}

const AddEditTrafficDestination = observer<AddEditTrafficDestinationProps>(({trafficClass, rule, mode, disabled}) => {

    const {trafficClassStore} = useStores();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [values, setValues] = useState<TrafficDestinationValues>(initialValues);
    const [hasErrors, setHasErrors] = useState(false);
    const [inputErrors, setInputErrors] = useState(initialValues);

    useEffect(() => {
        if (rule) {
            setValues({destination: rule.ip || rule.dns, port: rule.port});
        }
    }, [isModalOpen, rule]);

    const openTrafficDestinationModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setValues(initialValues);
        setHasErrors(false);
        setInputErrors(initialValues);
        setIsModalOpen(false);
    };

    const handleOk = () => {
        saveRule();
        closeModal();
    };

    const saveRule = () => {
        if (mode === 'add') {
            trafficClassStore.addRulesToTrafficClass(trafficClass, [values]);
        } else if (mode === 'edit') {
            trafficClassStore.editRule(trafficClass, rule, values);
        }
    };

    const hasDuplicates = useMemo(() =>
        trafficClassStore.data.some(tc => tc.rules.some((r =>
                !(tc.index === trafficClass.index && r.index === rule?.index) &&
                r.port === (values.port || '*') &&
                (r.ip === values.destination || r.dns?.toLowerCase() === values.destination.toLowerCase())
        ))
    ), [values]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldType: string) => {
        const inputValue = e.target.value;
        const newDestTypeToValuesMap =  {...values, [fieldType]: inputValue};
        setValues(newDestTypeToValuesMap);

        let isInvalidValue = false;
        if (fieldType === 'destination') {
            isInvalidValue = !validateIP(inputValue) && !isValidDomain(inputValue, {wildcard: true});
        } else if (fieldType ==='port' && inputValue.length) {
            isInvalidValue = !validatePort(inputValue);
        }

        const errorMessage = isInvalidValue ? `Invalid ${fieldType}` : '';
        const newInputErrors = { ...inputErrors, [fieldType]: errorMessage };

        setInputErrors(newInputErrors);
        setHasErrors(Object.values(newInputErrors).some((error) => error !== ''));
    };

    const renderModalBody = () => {
        return (
            <>
                <div className="url-ip-fields-wrapper">
                    {Object.keys(values).map(fieldType => (
                        <div className="textarea-wrapper" key={fieldType}>
                            <div className="select-label">{fieldType}</div>
                            <Input
                                className={`input ${fieldType}-input`}
                                placeholder={fieldType === 'destination' ? `Add ${fieldType}` : '*'}
                                value={values[fieldType]}
                                onChange={(e) => handleInputChange(e, fieldType)}
                            />
                            {inputErrors[fieldType] && (
                                <div className="error-message">{inputErrors[fieldType]}</div>
                            )}
                        </div>)
                    )}
                </div>

                {hasDuplicates &&
                    <div className="error-message">Duplicate value</div>}
            </>
        );
    };

    return (
        <>
            {mode === 'edit' && <Button className='edit-cell' onClick={openTrafficDestinationModal}>
                <PencilIcon/>
            </Button>}
            {mode === 'add' && <Button
                className={"add-url-btn secondary-btn"}
                type="primary"
                disabled={disabled}
                onClick={openTrafficDestinationModal}>
                + URL/IP
            </Button>}
            <CustomModal
                isOpen={isModalOpen}
                width="600px"
                title={mode === 'edit' ? 'Edit Destination' : `Add destination to ${trafficClass.name}`}
                okText={mode === 'add' ? "Add" : "Apply"}
                onOk={handleOk}
                okButtonProps={{ disabled: hasErrors || !values.destination || hasDuplicates}}
                cancelButtonProps={{ className: 'secondary-btn' }}
                onCancel={closeModal}>
                {renderModalBody()}
            </CustomModal>
        </>
    );
});

export default AddEditTrafficDestination;