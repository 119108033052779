import { BaseStore } from './base.store';
import { InsightData, TimeRange } from '../../utils/model';
import { RootStore } from './index';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BEGetAlertsRequest, convertFiltersUIToBE, UIAlertsFilters } from '../../dtos/insights-filter-conversion';
import { InsightType } from '../../utils/enums';
import insightsService from '../../services/insights.service';
import { convertRangeSelectedToRangeObj, convertSavedFilterToObject } from '../../utils/parse';

export class DashboardStore extends BaseStore {
    public data: InsightData[] = [];
    public filter: UIAlertsFilters;
    public totalRecords: number = 0;
    public selectedRangeIndex = 1;
    public dateRangeObj: TimeRange;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            data: observable,
            filter: observable,
            load: action,
            setFilter: action,
            initializeFilter: action,
            sizeSummary: computed,
            selectedRangeIndex: observable,
            setSelectedRangeIndex: action,
            dateRangeObj: observable,
            setDateRangeObj: action
        });

        this.initializeFilter();
    }

    initializeFilter() {
        const storedFilter = localStorage.getItem('dashboardFilter');
        if (storedFilter) {
            this.filter = convertSavedFilterToObject(storedFilter);
        } else {
            this.filter = {
                dateRange: 'Last 7 days',
                useCase: 'volumetric',
            }
        }
    }

    public async load() {
        try {
            this.setIsLoading(true);
            this.filter.customerName = this.rootStore.customerStore.selectedCustomer.name;
            const recordsLimit = this.rootStore.useCaseStore.dashboardRecordsLimit;
            const {notifications, totalRecordCount} = await insightsService.getInsightsList(convertFiltersUIToBE(this.filter, BEGetAlertsRequest), InsightType.ALERT, recordsLimit);
            const resWithDisplayName = await this.rootStore.insightsStore.setDisplayNames(notifications);
            runInAction(() => {
                this.data = resWithDisplayName;
                this.totalRecords = totalRecordCount;
                this.setIsLoading(false);
            });
        } catch (e) {
            runInAction(() => {
                this.setIsLoading(false);
            });
        }
    }

    public setFilter(filter: UIAlertsFilters) {
        this.filter = filter;
        localStorage.setItem('dashboardFilter', JSON.stringify(filter));
    }

    get sizeSummary(): string {
        const localDataLength = this.data.length;
        const dbDataLength = this.totalRecords;
        return localDataLength < dbDataLength ? ` (${localDataLength} of ${dbDataLength})` : '';
    }

    public setSelectedRangeIndex(val: number) {
        this.selectedRangeIndex = val;
    }

    public setDateRangeObj(val: number) {
        this.dateRangeObj = convertRangeSelectedToRangeObj(val);
    }
}
