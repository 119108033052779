import React from 'react';
import { ReactComponent as TrendNoneArrow } from '../../../assets/svg/trend-none-arrow.svg';
import { ReactComponent as TrendUpArrow } from '../../../assets/svg/trend-up-arrow.svg';
import { ReactComponent as TrendDownArrow } from '../../../assets/svg/trend-down-arrow.svg';
import './trend-arrow.scss';

interface TrendArrowProps {
    percentChange: number | null;
    trend: string | null;
}

const TrendArrow: React.FC<TrendArrowProps> = ({ percentChange, trend }) => {
    let ArrowComponent: React.FC<React.SVGProps<SVGSVGElement>>;
    let additionalClassName = '';

    if (!percentChange) {
        ArrowComponent = TrendNoneArrow;
        additionalClassName = 'none';
    } else if (trend === 'up') {
        ArrowComponent = TrendUpArrow;
        additionalClassName = 'up';
    } else {
        ArrowComponent = TrendDownArrow;
        additionalClassName = 'down';
    }

    return <ArrowComponent className={`trend-arrow ${additionalClassName}`} />;
};

export default TrendArrow;
