import React, { useMemo, useState, useEffect } from 'react';
import './issue-management-table.scss';
import { Category, CATEGORY_VALUES, IssueDetail, Severity, SEVERITY_VALUES, TimeRange } from '../../../utils/model';
import { ReactComponent as ArrowUp } from '../../../assets/svg/arrows-vertical.svg';
import { ReactComponent as Arrow } from '../../../assets/svg/arrow-left.svg';
import { ReactComponent as Chevron } from '../../../assets/svg/chevron-grey.svg';
import { ReactComponent as ChevronRight } from '../../../assets/svg/chevron-right.svg';
import { StatusColors } from '../security-dashboard/security-dashboard.constant';
import { Button, Popover } from 'antd';
import { FilterGroup } from './filter-group';
import SecurityIssueDetails from '../security-issue-details/security-issue-details';
import { useLocation } from 'react-router';
import Footer from '../security-dashboard/footer/footer';
import RangePicker from '../../shared/range-picker/range.picker';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store';
import { Link } from 'react-router-dom';
import { convertRangeSelectedToRangeObj } from '../../../utils/parse';
import widgetsService from '../../../services/widgets.service';
import LoadingWrapper from '../../shared/loading-wrapper/loading-wrapper';
import Skeleton from 'react-loading-skeleton';
import { WIDGET_NAME_MAP } from '../../../utils/widget-map';
import { v4 as uuidv4 } from 'uuid';
import TrendArrow from '../../shared/trend-arrow/trend-arrow';

const IssueManagementTable: React.FC = observer(() => {
    const { dashboardStore, customerStore } = useStores();

    const [selectedIssue, setSelectedIssue] = useState<IssueDetail | null>(null);
    const [sortField, setSortField] = useState<keyof IssueDetail>('name');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [issuesPerPage, setIssuesPerPage] = useState(15);
    const [minHeight, setMinHeight] = useState(600);
    const [rangeIndex, setRangeIndex] = useState(dashboardStore.selectedRangeIndex);
    const [issues, setIssues] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [rangeObject, setRangeObject] = useState<TimeRange | null>(null);
    const defaultFilters = { severity: SEVERITY_VALUES, category_title: CATEGORY_VALUES };
    const [filters, setFilters] = useState<{ severity: Severity[], category_title: Category[] }>(defaultFilters);
    const location = useLocation();

    useEffect(() => {
        const calculateIssuesPerPage = () => {
            const viewportHeight = window.innerHeight;
            const remainingHeight = viewportHeight - 360; // Subtract the height of other elements
            const rows = Math.floor(remainingHeight / 40);
            if (rows) {
                setMinHeight(rows * 41); // Include the border
                setIssuesPerPage(rows);
            }
        };

        calculateIssuesPerPage();
        window.addEventListener('resize', calculateIssuesPerPage);

        return () => window.removeEventListener('resize', calculateIssuesPerPage);
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const severity = queryParams.get('importance')?.split(',') || defaultFilters.severity;
        const category_title = queryParams.get('category')?.split(',') || defaultFilters.category_title;
        setFilters({ severity, category_title });
    }, [location.search]);

    useEffect(() => {
        setIsLoading(true);

        if (customerStore.selectedCustomer) {
            const range = convertRangeSelectedToRangeObj(rangeIndex);
            setRangeObject(range);
            widgetsService.getWidgetData(customerStore.selectedCustomer.name, WIDGET_NAME_MAP.SECURITY_ISSUES_FULL, range)
                .then((res) => {
                    setIssues(res);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false)
                });
        }
    }, [rangeIndex, customerStore.selectedCustomer]);

    const handleSort = (field: keyof IssueDetail) => {
        setSortOrder(sortField === field && sortOrder === 'asc' ? 'desc' : 'asc');
        setSortField(field);
    };

    const handleFilterChange = (key: keyof typeof filters, values: string[]) => {
        setFilters({ ...filters, [key]: values });
        setCurrentPage(1);
    };

    const onRowClick = (row: IssueDetail) => {
        setSelectedIssue(row);
    };

    const handleMenuClick = ({ key }: { key: string }) => {
        setRangeIndex(Number(key));
    };

    const filteredIssues = useMemo(() => issues.filter(issue => {
        const importanceMatch = filters.severity.includes(issue.severity);
        const categoryMatch = filters.category_title.includes(issue.category_title);
        return importanceMatch && categoryMatch;
    }), [issues, filters]);

    const sortedIssues = useMemo(() => [...filteredIssues].sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    }), [filteredIssues, sortField, sortOrder]);

    const currentIssues = useMemo(() => {
        const indexOfLastIssue = currentPage * issuesPerPage;
        return sortedIssues.slice(indexOfLastIssue - issuesPerPage, indexOfLastIssue);
    }, [sortedIssues, currentPage, issuesPerPage]);

    const headers = [
        { label: 'Security issue', field: 'name' },
        { label: 'Importance', field: 'severity' },
        { label: 'Category', field: 'category_title' },
        { label: 'Max devices', field: 'affected_devices' },
        { label: 'APNs', field: 'distinct_apns' },
    ];

    const filterContent = (
        <div>
            <FilterGroup
                title="Importance"
                options={SEVERITY_VALUES}
                selectedValues={filters.severity}
                onChange={(values) => handleFilterChange('severity', values)}
            />
            <FilterGroup
                title="Type"
                options={CATEGORY_VALUES}
                selectedValues={filters.category_title}
                onChange={(values) => handleFilterChange('category_title', values)}
            />
        </div>
    );

    const renderSkeletonCells = (numCells: number, numRows: number) => {
        return (
            <div>
                {Array.from({ length: numRows }).map(() => (
                    <div key={`row-${uuidv4()}`} className="row skeleton-row">
                        {Array.from({ length: numCells }).map(() => (
                            <div key={`cell-${uuidv4()}`} className="skeleton-cell-wrap">
                                <Skeleton className="skeleton-cell" />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    const renderTableBody = () => {
        if (isLoading) {
            return renderSkeletonCells(headers.length, issuesPerPage);
        } else if (currentIssues.length === 0) {
            return (
                <div className="empty-state">
                    <div className="empty-state-title">No issues found</div>
                    <div className="empty-state-desc">There are currently no issues to display. Please adjust your filters</div>
                </div>
            );
        } else {
            return currentIssues.map((issue: IssueDetail) => (
                <div className={`row ${issue.id === selectedIssue?.id ? 'active' : ''}`} key={issue.id}
                     onClick={() => onRowClick(issue)}
                     onKeyDown={() => onRowClick(issue)}>
                    <div className="cell">{issue.name}</div>
                    <div className="cell importance">
                        <div className="importance-wrapper">
                            <div className="importance" style={{
                                color: StatusColors[issue.severity].color,
                                borderColor: StatusColors[issue.severity].color}}>
                                {issue.severity}
                            </div>
                        </div>
                    </div>
                    <div className="cell">{issue.category_title}</div>
                    <div className="cell devices">
                        <div className="device-number">{issue.affected_devices.toLocaleString('en-US')}</div>
                        <TrendArrow trend={issue.trend} percentChange={issue.percentChange} />
                    </div>
                    <div className="cell apn-number">{issue.distinct_apns.length}</div>
                </div>
            ));
        }
    };

    return (
        <div className="issue-management-page">
            <div className="app-breadcrumb">
                <Link className="breadcrumb-back" to={"../security-dashboard"}>
                    Security
                    <ChevronRight/>
                </Link>
                <div>Security issues</div>
            </div>

            <div className="issue-management-header">
                <div className="title">Security Issues <LoadingWrapper isLoading={isLoading} skeletonProps={{width: 100}}>({filteredIssues.length})</LoadingWrapper></div>
                <div className="issue-management-actions">
                    <Popover content={filterContent} trigger="click" overlayClassName="filter-popup" arrow={false}>
                        <Button className="filter-button">
                            <span>Filter by</span><Chevron />
                        </Button>
                    </Popover>
                    <RangePicker
                        selectedRangeIndex={rangeIndex}
                        handleMenuClick={handleMenuClick}
                    />
                </div>
            </div>
            <div className="issue-management-table">
                <div className="table-header">
                    {headers.map(header => (
                        <div
                            key={header.field}
                            className="header-cell"
                            onClick={() => handleSort(header.field as keyof IssueDetail)}
                            onKeyDown={() => handleSort(header.field as keyof IssueDetail)}
                        >
                            {header.label}
                            {sortField === header.field &&
                                <ArrowUp className={`sort-icon ${sortOrder === 'asc' ? 'asc' : 'desc'}`}/>}
                        </div>
                    ))}
                </div>
                <div className="table-body" style={{ minHeight: `${minHeight}px` }}>
                    {renderTableBody()}
                </div>
                <div className="pagination">
                    <button className="prev-next-btn" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                        <Arrow/><span>Prev</span>
                    </button>
                    <div className="pagination-number">
                        {Array.from({length: Math.ceil(sortedIssues.length / issuesPerPage)}, (_, i) => (
                            <button
                                key={i + 1}
                                onClick={() => setCurrentPage(i + 1)}
                                className={currentPage === i + 1 ? 'active' : ''}>
                                {i + 1}
                            </button>
                        ))}
                    </div>

                    <button className="prev-next-btn" onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage === Math.ceil(sortedIssues.length / issuesPerPage)}>
                        <span>Next</span><Arrow/>
                    </button>
                </div>
            </div>
            <Footer />
            {selectedIssue && (
                <SecurityIssueDetails
                    issue={selectedIssue}
                    onClose={() => setSelectedIssue(null)}
                    customerName={customerStore.selectedCustomer.name}
                    dateRangeObj={rangeObject}
                />
            )}
        </div>
    );
});

export default IssueManagementTable;