import React, { useEffect, useMemo, useState } from 'react';
import './security-issue-importance.scss';
import DonutChart from '../donut-chart/donut-chart';
import { StatusColors } from '../security-dashboard.constant';
import { observer } from 'mobx-react-lite';
import widgetsService from '../../../../services/widgets.service';
import { useStores } from '../../../store';
import { SecurityIssue, Severity } from '../../../../utils/model';
import LoadingWrapper from '../../../shared/loading-wrapper/loading-wrapper';
import { WIDGET_NAME_MAP } from '../../../../utils/widget-map';

const SecurityIssueImportance = observer(() => {

    const { dashboardStore, customerStore } = useStores();

    const [data, setData] = useState<SecurityIssue[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [chartSize, setChartSize] = useState(280);

    useEffect(() => {
        setIsLoading(true);
        if (customerStore.selectedCustomer) {
            widgetsService.getWidgetData(customerStore.selectedCustomer.name, WIDGET_NAME_MAP.SECURITY_ISSUES_IMPORTANCE, dashboardStore.dateRangeObj)
                .then((res: SecurityIssue[]) => {
                    setData(res);
                    setIsLoading(false);
                }).catch(() => setIsLoading(false));
        }
    }, [dashboardStore.dateRangeObj, customerStore.selectedCustomer]);

    useEffect(() => {
        const updateChartSize = () => {
            let size = 280;
            if (window.innerWidth < 1280) {
                size = 180;
            }
            setChartSize(size);
        };
        updateChartSize();
        window.addEventListener('resize', updateChartSize);

        return () => window.removeEventListener('resize', updateChartSize);
    }, []);


    const getStatusCount = (status: Severity) => {
        return data?.find(d => d.severity === status)?.count || 0;
    };

    const totalValue = useMemo(() =>
        data?.reduce((acc, d) => acc + d.count, 0)
    , [data]);

    return (
        <div className="security-issue-importance">
            <div className="security-importance-title">
                <LoadingWrapper isLoading={isLoading} skeletonProps={{ height: 28 }}>
                    Security Issue Importance
                </LoadingWrapper>
            </div>
            <div className="security-importance-content">
                <div className="security-legend">
                    <div className="security-legend-title">
                        <LoadingWrapper isLoading={isLoading} skeletonProps={{ height: 20, width: 100 }}>
                            Count
                        </LoadingWrapper>
                    </div>
                    <div className="security-legend-items">
                        {Object.keys(StatusColors).map((status: Severity) => (
                            <LoadingWrapper key={status} isLoading={isLoading} skeletonProps={{ className: 'count-items-loader' }}>
                                <div className="legend-item">
                                    <div className="legend-color" style={{ backgroundColor: StatusColors[status].color }} />
                                    <div className="legend-count">{getStatusCount(status).toLocaleString('en-US')}</div>
                                    <div className="legend-text">{status}</div>
                                </div>
                            </LoadingWrapper>
                        ))}
                    </div>
                </div>
                <LoadingWrapper isLoading={isLoading} skeletonProps={{ circle: true, className: 'donut-chart-loader' }}>
                    <div className="security-chart">
                        {totalValue ? <DonutChart data={data}
                                                  totalValue={totalValue?.toLocaleString('en-US')}
                                                  size={chartSize} /> :
                            <div className="no-result">No issues found</div>
                        }
                    </div>
                </LoadingWrapper>
            </div>
        </div>
    );
});

export default SecurityIssueImportance;
