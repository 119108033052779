import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useStores } from '../../store';
import { useParams } from 'react-router-dom';

export const AnomalyWidgetsWrapper = observer(() => {

    const { useCaseStore} = useStores();
    const { customerName, useCaseName } = useParams<{ customerName: string; useCaseName: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        if (useCaseStore.model && useCaseStore.data) {
            if (!useCaseName) {
                if (!useCaseStore.isActiveByUseCaseName(useCaseStore.currentUseCaseModel?.name) || useCaseStore.currentUseCaseModel?.hideOnNavbar) {
                    useCaseStore.setSelectedUseCaseToFirstActive();
                }
                navigate(`${useCaseStore.currentUseCaseModel.name}`, { replace: true });
            } else {
                useCaseStore.setSelectedUseCaseByName(useCaseName);
            }
        }
    }, [useCaseName, useCaseStore.currentUseCaseModel, useCaseStore.model, customerName, useCaseStore.data]);

    return <div className='anomalies'>
            <Outlet />
        </div>
});