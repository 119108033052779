import React from 'react';
import { DATE_OPTIONS } from '../../../utils/constants';
import { ReactComponent as Chevron } from '../../../assets/svg/chevron-grey.svg';
import './range-picker.scss';
import { Dropdown, MenuProps } from 'antd';

interface RangePickerProps {
    selectedRangeIndex: number;
    handleMenuClick: ({ key }: { key: string }) => void;
}

const RangePicker: React.FC<RangePickerProps> = ({ selectedRangeIndex, handleMenuClick }) => {

    const items: MenuProps['items'] = DATE_OPTIONS.map((option, index) => ({
        key: index.toString(),
        label: option.displayName,
        className: index === selectedRangeIndex ? 'active' : '',
    }));

    return (
        <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={['click']} overlayClassName="dropdown-dark">
            <button className="dropdown-dark-btn range-btn">
                {DATE_OPTIONS[selectedRangeIndex].displayName}
                <Chevron />
            </button>
        </Dropdown>
    );
};

export default RangePicker;