import { BaseStore } from './base.store';
import { RootStore } from './index';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import insightsService from '../../services/insights.service';
import { InsightsSubMenu, InsightType } from '../../utils/enums';
import { InsightData } from '../../utils/model';
import {
    BEGetAlertsRequest,
    BEGetReportsRequest,
    convertFiltersUIToBE,
    UIAlertsFilters,
    UIReportsFilters
} from '../../dtos/insights-filter-conversion';
import { convertSavedFilterToMap } from '../../utils/parse';

export class InsightsStore extends BaseStore {

    public data: Map<InsightType, InsightData[]> = new Map([
        [InsightType.ALERT, []],
        [InsightType.REPORT, []],
        [InsightType.ACTIVITY, []],
        [InsightType.TRAFFIC_CLASSES, []],
    ]);
    public selectedItems: Map<InsightType, string[]> = new Map([
        [InsightType.ALERT, []],
        [InsightType.REPORT, []],
        [InsightType.ACTIVITY, []],
        [InsightType.TRAFFIC_CLASSES, []],
    ]);
    public totalRecords: Map<InsightType, number> = new Map([
        [InsightType.ALERT, 0],
        [InsightType.REPORT, 0],
    ]);
    public activeSubMenu: InsightsSubMenu = InsightsSubMenu.REPORTS;
    public filter: Map<InsightType, UIReportsFilters | UIAlertsFilters>;

    constructor(rootStore: RootStore) {
        super(rootStore);

        makeObservable(this, {
            data: observable,
            filter: observable,
            selectedItems: observable,
            loadInsights: action,
            activeSubMenu: observable,
            setActiveSubMenu: action,
            addSelectedItem: action,
            removeSelectedItem: action,
            setSelectedItems: action,
            activeInsightType: computed,
            activeSelectedItems: computed,
            activeFilter: computed,
            setFilter: action,
            sizeSummary: computed,
            initializeFilter: action,
            activityPanelId: computed,
            trafficClassesPanelId: computed,
        });

        this.initializeFilter();
    }

    initializeFilter() {
        const storedFilter = localStorage.getItem('insightsFilter');
        const defaultFilters = new Map([
            [InsightType.ALERT, {
                dateRange: 'Last 30 days',
                useCase: 'volumetric'
            }],
            [InsightType.REPORT, {
                dateRange: 'Last 7 days',
                useCase: '',
                reportFrequency: ''
            }],
            [InsightType.ACTIVITY, {
                dateRange: 'Last 30 days',
            }],
            [InsightType.TRAFFIC_CLASSES, {
                dateRange: 'Last 30 days',
                apns: ''
            }],
        ]);

        let storedFilters = new Map();
        if (storedFilter) {
            storedFilters = convertSavedFilterToMap(storedFilter);
            storedFilters.forEach((value, key) => {
                defaultFilters.set(key, value);
            });
        }
        this.filter = defaultFilters;
    }


    public async loadInsights() {
        try {
            this.setIsLoading(true);
            let activeFilter = this.filter.get(this.activeInsightType);
            activeFilter.customerName = this.rootStore.customerStore.selectedCustomer.name;
            const backendRequestType = this.activeInsightType === InsightType.ALERT ? BEGetAlertsRequest : BEGetReportsRequest;
            const recordsLimit = this.rootStore.useCaseStore.insightsRecordsLimit;
            let {
                notifications,
                totalRecordCount
            } = await insightsService.getInsightsList(convertFiltersUIToBE(activeFilter, backendRequestType), this.activeInsightType, recordsLimit);
            if (this.activeInsightType === InsightType.ALERT) {
                notifications = this.getAlertNotificationData(notifications);
            }
            const resWithDisplayName = await this.setDisplayNames(notifications);
            runInAction(() => {
                this.data.set(this.activeInsightType, resWithDisplayName);
                this.setIsLoading(false);
                this.selectedItems.set(this.activeInsightType, []);
                this.totalRecords.set(this.activeInsightType, totalRecordCount);
            });
        } catch (e) {
            runInAction(() => {
                this.setIsLoading(false);
            });
        }
    }

    public setActiveSubMenu(activeSubMenu: InsightsSubMenu) {
        this.activeSubMenu = activeSubMenu;
    }

    public addSelectedItem(type: InsightType, id: string) {
        const items = this.selectedItems.get(type);
        items.push(id);
        this.selectedItems.set(type, items);
    }

    public removeSelectedItem(type: InsightType, id: string) {
        const items = this.selectedItems.get(type);
        const filteredItems = items.filter((itemId) => itemId !== id);
        this.selectedItems.set(type, filteredItems);
    }

    public setSelectedItems(type: InsightType, ids: string[]) {
        this.selectedItems.set(type, ids);
    }

    get activeInsightType(): InsightType {
        switch (this.activeSubMenu) {
            case InsightsSubMenu.REPORTS:
                return InsightType.REPORT;
            case InsightsSubMenu.ALERTS:
                return InsightType.ALERT;
            case InsightsSubMenu.ACTIVITY:
                return InsightType.ACTIVITY;
            case InsightsSubMenu.TRAFFIC_CLASSES:
                return InsightType.TRAFFIC_CLASSES;
            default:
                return InsightType.REPORT;
        }
    }

    get activityPanelId() {
        return this.rootStore.useCaseStore.model?.activityPanelId;
    }

    get trafficClassesPanelId() {
        return this.rootStore.useCaseStore.model?.trafficClassesPanelId;
    }

    get activeSelectedItems(): string[] {
        return this.selectedItems.get(this.activeInsightType);
    }

    get activeFilter(): UIReportsFilters | UIAlertsFilters {
        return this.filter.get(this.activeInsightType);
    }

    public setFilter(filter: UIReportsFilters | UIAlertsFilters) {
        this.filter.set(this.activeInsightType, filter);
        localStorage.setItem('insightsFilter', JSON.stringify(Array.from(this.filter.entries())));
    }

    async setDisplayNames(res: any) {
        const displayValues = this.rootStore.useCaseStore.useCaseDisplayValues;
        return res.map(item => {
            return {...item, displayName: displayValues.get(item.useCase).displayName}
        });
    }

    get sizeSummary(): string {
        const localDataLength = this.data.get(this.activeInsightType).length;
        const dbDataLength = Math.ceil(this.totalRecords.get(this.activeInsightType) / 2);
        return localDataLength < dbDataLength ? ` (${localDataLength} of ${dbDataLength})` : '';
    }

    getAlertNotificationData(notifications: any) {
        const alerts = [];
        const alertMap = new Map<string, any[]>();
        notifications.forEach((alert) => {
            const key = `${alert.apns}${alert.alertRaiseId}`;
            if (alertMap.has(key)) {
                alertMap.get(key).push(alert);
            } else {
                alertMap.set(key, [alert]);
            }
        });
        alertMap.forEach((alertsArray) => {
            const raiseAlert = alertsArray.find(item => item.raiseSupressType === 'RAISE');
            if (raiseAlert) {
                const suppressAlert = alertsArray.find(item => item.raiseSupressType === 'SUPPRESS');
                if (suppressAlert) {
                    raiseAlert.suppressId = suppressAlert.id;
                    raiseAlert.alertSuppressDateTime = suppressAlert.alertSuppressDateTime;
                }
                alerts.push(raiseAlert);
            }
        });
        return alerts;
    }
}
