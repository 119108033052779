import React, { useEffect, useState, useRef, useMemo } from 'react';
import moment from 'moment';
import { ReactComponent as CloseIcon } from '../../../assets/svg/x-blue.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/svg/chevron-up.svg';
import { ReactComponent as SearchIcon } from '../../../assets/svg/searchbox.svg';
import { StatusColors } from '../security-dashboard/security-dashboard.constant';
import RecommendedAction from '../security-dashboard/recommended-action/recommended-action';
import { Action, IssueDetail, TimeRange } from '../../../utils/model';
import './security-issue-details.scss';
import Speedometer from '../speedometer/speedometer';
import AffectedDevices from '../affected-devices/affected-devices';
import widgetsService from '../../../services/widgets.service';
import { WIDGET_NAME_MAP } from '../../../utils/widget-map';
import LoadingWrapper from '../../shared/loading-wrapper/loading-wrapper';
import { fillMissingDates } from '../../../utils/parse';
import TrendArrow from '../../shared/trend-arrow/trend-arrow';

interface SecurityIssueDetailsProps {
    issue: IssueDetail;
    customerName: string;
    dateRangeObj: TimeRange
    onClose: () => void;
}

const SecurityIssueDetails: React.FC<SecurityIssueDetailsProps> = ({ issue, customerName, dateRangeObj,  onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [deviceList, setDeviceList] = useState<{ imei: string, imsi: string }[]>([]);
    const [filteredDeviceList, setFilteredDeviceList] = useState<{ imei: string, imsi: string }[]>([]);
    const [isDeviceListCollapsed, setIsDeviceListCollapsed] = useState(false);
    const [isApnListCollapsed, setIsApnListCollapsed] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setIsLoading(true);
        widgetsService.getWidgetData(
            customerName,
            WIDGET_NAME_MAP.DEVICES_FOR_ISSUE,
            dateRangeObj,
            { extraParameters : {  id: issue.id }}
        ).then(res => {
            setDeviceList(res);
            setIsLoading(false);
        }).catch(() => setIsLoading(false));

    }, [issue.id, dateRangeObj]);

    useEffect(() => {
        if (issue) {
            const filtered = deviceList.filter(device =>
                device.imei.toLowerCase().includes(searchTerm.toLowerCase()) ||
                device.imsi.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredDeviceList(filtered);
        }
    }, [searchTerm, issue, deviceList]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const { target } = event;
            let className = '';
            if (target instanceof HTMLElement) {
                className = target.className;
            }
            if (containerRef.current && !containerRef.current.contains(target as Node) && !className.includes('cell')) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose();
        }, 400);
    };

    const recommendedAction: Action = useMemo(() => {
        return {
            id: issue.id,
            recommended_action: issue.recommended_action,
            severity: issue.severity
        }
    }, [issue.recommended_action]);

    return (
        <div className={`security-issue-details ${isClosing ? 'closing' : 'open'}`} ref={containerRef}>
            <div className="security-issue-header">
                <div className="security-issue-details-title">{issue.name}</div>
                <button onClick={handleClose}><CloseIcon /></button>
            </div>
            <div className="details">
                <div className="issue-details-time">{moment(issue.last_date).format('MMMM D, YYYY, h:mm A z')}</div>
                <div className="issue-details-widgets">
                    <div className="importance-status widget" style={{color: StatusColors[issue.severity].color}}>
                        <Speedometer status={issue.severity}/>
                    </div>
                    <div className="issue-details-trend widget">
                        <AffectedDevices deviceNumber={deviceList.length} percent={issue.percentChange}
                                         trendDirection={issue.trend as 'up' | 'down'}
                                         points={fillMissingDates(issue.devices_date_points,dateRangeObj)}/>
                    </div>
                </div>
                <div className="issue-details-desc">{issue.long_description}</div>
                <div className="issue-details-recommended-action">
                    <div className="issue-details-recommended-title">Recommended Action</div>
                    <RecommendedAction action={recommendedAction}/>
                </div>
                <div className="issue-details-affected-apns">
                    <div className="issue-details-affected-header">
                        <div className="section-header-title">Affected APNs<span>{issue.distinct_apns.length}</span></div>
                        <button onClick={() => setIsApnListCollapsed(!isApnListCollapsed)}>
                            <ExpandIcon className={isApnListCollapsed ? 'collapsed' : 'expanded'}/>
                        </button>
                    </div>
                    <div className={`affected-apns-list ${isApnListCollapsed ? 'collapsed' : ''}`}>
                        {issue.distinct_apns.map(apn => (
                            <div key={apn}>{apn}</div>
                        ))}
                    </div>
                </div>
                <div className="device-section">
                    <div className="device-section-header">
                        <div className="section-header-title">Devices<span>{filteredDeviceList.length}</span></div>
                        <div className="section-header-info">
                            <span>{issue.percentChange}% {issue.trend}</span>
                            <span>
                                <TrendArrow trend={issue.trend} percentChange={issue.percentChange} />
                            </span>
                            <button onClick={() => setIsDeviceListCollapsed(!isDeviceListCollapsed)}>
                                <ExpandIcon className={isDeviceListCollapsed ? 'collapsed' : 'expanded'}/>
                            </button>
                        </div>
                    </div>
                    <div className={`device-list-wrapper ${isDeviceListCollapsed ? 'collapsed' : ''}`}>
                        <div className="search-box">
                            <input
                                type="text"
                                placeholder="Search devices by IMEI or IMSI.."
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                            <SearchIcon className="search-icon"/>
                        </div>
                        <LoadingWrapper isLoading={isLoading} skeletonProps={{ count: issue.affected_devices }}>
                            <div className="device-list">
                                {filteredDeviceList.map(device => (
                                    <div key={device.imei} className="device-item">
                                        <span>IMEI: {device.imei}</span>
                                        <span>IMSI: {device.imsi}</span>
                                    </div>
                                ))}
                            </div>
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SecurityIssueDetails;
