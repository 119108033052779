import React, { useEffect, useState } from 'react';
import './issue-categories.scss';
import BarChart from '../bar-chart/bar-chart';
import { observer } from 'mobx-react-lite';
import widgetsService from '../../../../services/widgets.service';
import { useStores } from '../../../store';
import { WIDGET_NAME_MAP } from '../../../../utils/widget-map';
import LoadingWrapper from '../../../shared/loading-wrapper/loading-wrapper';
import { CATEGORIES_CONFIG } from '../security-dashboard.constant';
import { fillMissingDates } from '../../../../utils/parse';

const IssueCategories = observer(() => {

    const { dashboardStore, customerStore } = useStores();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if (customerStore.selectedCustomer) {
            widgetsService.getWidgetData(customerStore.selectedCustomer.name, WIDGET_NAME_MAP.ISSUES_CATEGORIES, dashboardStore.dateRangeObj)
                .then((res) => {
                    const filledData = fillMissingDates(res, dashboardStore.dateRangeObj);
                    setData(filledData);
                    setIsLoading(false);
                }).catch(() => setIsLoading(false));
        }
    }, [dashboardStore.dateRangeObj, customerStore.selectedCustomer]);

    return (
        <div className="issue-categories">
            <div className="title">
                <LoadingWrapper isLoading={isLoading} skeletonProps={{ height: 26 }}>
                    Issue Categories
                </LoadingWrapper>
            </div>
            <LoadingWrapper isLoading={isLoading} skeletonProps={{ height: 250 }}>
                <BarChart data={data} />
                <div className="legend">
                    {CATEGORIES_CONFIG.map((config) => (
                        <div key={config.fieldName} className="legend-item">
                            <div className="legend-color"
                                 style={{
                                     background: `linear-gradient(to left, ${config.color} 0%, rgba(255, 255, 255, 0) 100%)`
                                 }}
                            />
                            <span className="legend-text">{config.fieldName}</span>
                        </div>
                    ))}
                </div>
            </LoadingWrapper>
        </div>
    );
});

export default IssueCategories;
