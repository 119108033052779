import { DefaultTrafficClasses } from '../dtos/traffic-classes.dto';

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const IP_REGEX = '^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){2}(\\*|(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))\\.(\\*|(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))(/(3[0-2]|2[2-9]))?$';
export const UIBE_PREFIX = '/mti-ui-be/api';
export const GRAFANA_PREFIX = 'https://mtad-grafana.it.att.com/d/MaZGwy-4k/mti-ops-dashboard?orgId=1&from=now-7d&to=now&var-oemName=db_oem_events_';
export const ERROR_MSG = 'Functionality is not available at the moment, please try again later or contact support for more info.';
export const PAGINATION_PAGE_SIZES = [{label: '10', key: '10',}, {label: '25', key: '25',}, {label: '50', key: '50',}];
export const FREQUENCIES = [{label: 'All', value: ''}, {label: 'Daily', value: 'DAILY'}, {label: 'Weekly', value: 'WEEKLY'}];
export const MAX_DEST_VALUES = 200;
export const MAX_TRAFFIC_RULES = 500;
export const ALLOWED_NUMBER_INPUT_KEYS = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];

export interface DateOption {
    value: number;
    unit: any;
    dayOffsetFilter: number;
}

export const DATE_OPTIONS_MAP: Record<string, DateOption> = {
    'Last 1 hour': { value: 1, unit: 'hours', dayOffsetFilter: 0 },
    'Last 6 hours': { value: 6, unit: 'hours', dayOffsetFilter: 0 },
    'Last 12 hours': { value: 12, unit: 'hours', dayOffsetFilter: 0 },
    'Last 24 hours': { value: 24, unit: 'hours', dayOffsetFilter: 0 },
    'Last 7 days': { value: 7, unit: 'days', dayOffsetFilter: 0 },
    'Last 30 days': { value: 30, unit: 'days', dayOffsetFilter: 0 },
    'Yesterday': { value: 1, unit: 'days', dayOffsetFilter: 1 },
    'Previous 7 days': { value: 7, unit: 'days', dayOffsetFilter: 1 },
    'Previous 30 days': { value: 30, unit: 'days', dayOffsetFilter: 1 },
    'Past 10 days': { value: 10, unit: 'days', dayOffsetFilter: 2 }
};

export const TRAFFIC_CLASSES_DESC = {
    [DefaultTrafficClasses.STREAMING]: 'Audio and video streaming services',
    [DefaultTrafficClasses.TELEMATICS]: 'Data about vehicle performance, vehicle diagnostic information, environment metrics, tracking vehicle location',
    [DefaultTrafficClasses.NAVIGATION]: 'Mapping and GPS-based driver direction, location-based services',
    [DefaultTrafficClasses.FOTA]: 'Update of vehicle firmware and software over the cellular network',
    [DefaultTrafficClasses.MANAGEMENT]: 'Basic network services such as DNS and time synchronization, cloud services'

}

export const DATE_OPTIONS = [
    { displayName: 'Last 2 days', value: 2, unit: 'days' },
    { displayName: 'Last 7 days', value: 7, unit: 'days' },
    { displayName: 'Last 30 days', value: 30, unit: 'days' }
];