import { UIBE_PREFIX } from '../utils/constants';
import axiosService from './axios.service';
import { TimeRange } from '../utils/model';

class WidgetsService {

    public getWidgetData = async (customerName: string, widgetName: string, range: TimeRange, extraParameters?: Record<string, any>): Promise<any> => {
        let url = `${UIBE_PREFIX}/dashboard/getWidgetData?customerName=${customerName}`;
        const res = await axiosService.post(url, { fromDate: range.from, toDate: range.to, widgetName, ...extraParameters });
        return res.data;
    }
}

const widgetsService = new WidgetsService();
export default widgetsService;