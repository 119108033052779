import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import './table-issue.scss';
import { StatusColors } from '../security-dashboard.constant';
import { IssueDetail } from '../../../../utils/model';
import LoadingWrapper from '../../../shared/loading-wrapper/loading-wrapper';
import TrendArrow from '../../../shared/trend-arrow/trend-arrow';

interface TableIssueProps {
    categoryKey: string;
    categoryName: string;
    icon: ReactNode;
    data: IssueDetail[];
    total: number;
    color: string;
    onRowClick: (issue: IssueDetail) => void;
    isLoading: boolean;
}

const TableIssue: React.FC<TableIssueProps> = ({ categoryKey, categoryName, icon, data, total, color, onRowClick, isLoading }) => {
    return (
        <div className="table-issue">
            <div className="table-issue-header">
                <div className="table-issue-category">
                    <div className="icon">{icon}</div>
                    <div className="category-name">{categoryName}</div>
                    <LoadingWrapper isLoading={isLoading} skeletonProps={{ width: 30, height: 15}}>
                        <div className="total-issues" style={{ backgroundColor: color }}>{total || 0}</div>
                    </LoadingWrapper>
                </div>
                {data.length > 0 && <Link className="security-view-all" to={`../security-issue-management?category=${categoryKey}`}>View All</Link>}
            </div>
            <LoadingWrapper isLoading={isLoading} skeletonProps={{ height: 120 }}>
                {data.length === 0 ? (
                    <div className="empty-state">
                        <div className="empty-state-title">No {categoryName} Detected</div>
                        <div className="empty-state-desc">Your system is secure and free from any {categoryName}. Keep up the great work!</div>
                    </div>
                ) : (
                    <div className="table">
                        <div className="table-header">
                            {['Security issue', 'Importance', 'Max devices', 'APNs'].map((header) => (
                                <div className="header-cell" key={header}>{header}</div>
                            ))}
                        </div>
                        {data.map((item) => (
                            <div className="table-row" key={item.id} onClick={() => onRowClick(item)}
                                 onKeyDown={() => onRowClick(item)}>
                                <div className="cell use-case">{item.name}</div>
                                <div className="cell importance-wrapper">
                                    <div className="importance"
                                         style={{
                                             color: StatusColors[item.severity].color,
                                             borderColor: StatusColors[item.severity].color
                                         }}>
                                        {item.severity}
                                    </div>
                                </div>
                                <div className="cell devices">
                                    <div className="device-number">{item.affected_devices.toLocaleString('en-US')}</div>
                                    <TrendArrow trend={item.trend} percentChange={item.percentChange} />
                                </div>
                                <div className="cell apn-number">{item.distinct_apns.length}</div>
                            </div>
                        ))}
                    </div>
                )}
            </LoadingWrapper>
        </div>
    );
};

export default TableIssue;
