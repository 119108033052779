import React from 'react';
import './recommended-action.scss';
import { StatusColors } from '../security-dashboard.constant';
import { Action } from '../../../../utils/model';
import moment from 'moment';

interface RecommendedActionProps {
    action: Action;
}

const RecommendedAction: React.FC<RecommendedActionProps> = ({ action }) => {
    return (
        <div key={action.id} className="recommended-action-item">
            <div className="item-header">
                {action.severity && (
                    <div
                        className="recommended-status-indicator"
                        style={{backgroundColor: StatusColors[action.severity].color}}
                    ></div>
                )}
                <div className="title">{action.recommended_action}</div>
            </div>
            {action.last_date && <div className="item-details">
                <div className="timestamp">
                    Last detected: {moment(action.last_date).format('MMMM D, YYYY, h:mm A z')}
                </div>
            </div>}
        </div>
    );
};

export default RecommendedAction;
