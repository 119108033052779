import { BaseStore } from './base.store';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from './index';
import trafficClassService from '../../services/traffic-class.service';
import {
    DefaultTrafficClasses,
    TrafficClass,
    TrafficClassRule,
    TrafficClassRuleType
} from '../../dtos/traffic-classes.dto';
import isValidDomain from 'is-valid-domain';
import { validateIP } from '../../utils/util';
import { TRAFFIC_CLASSES_DESC } from '../../utils/constants';
import { message } from 'antd';

export class TrafficClassStore extends BaseStore {
    public data: TrafficClass[] = [];
    public isDirty = false;
    public collapsedClasses: { [index: string]: boolean } = {};

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            data: observable,
            isDirty: observable,
            collapsedClasses: observable,
            allRulesCount: computed,
            load: action,
            save: action,
            removeRuleAndReorder: action,
            editRule: action,
            addRulesToTrafficClass: action,
            addClass: action,
            editClass: action,
            removeClass: action,
            reorderClasses: action,
            reorderRules: action,
            collapseAll: action,
            toggleCollapseExpand: action,
            isAllCollapsed: computed,
        });
    }

    get allRulesCount() {
        return this.data.reduce((count, trafficClass) => count + trafficClass.rules.length, 0);
    }

    public async load() {
        try {
            this.setIsLoading(true);
            const customer = this.rootStore.customerStore?.selectedCustomer?.name;
            const trafficClassesValues = await trafficClassService.getTrafficClasses(customer);
            runInAction(() => {
                this.data = this.initializeData(trafficClassesValues);
                this.isDirty = false;
                this.collapseAll();
                this.setIsLoading(false);
            });
        } catch (e) {
            runInAction(() => this.setIsLoading(false));
        }
    }

    public async save() {
        try {
            this.setIsLoading(true);
            const customer = this.rootStore.customerStore?.selectedCustomer?.name;
            await trafficClassService.saveTrafficClasses(customer, this.data);
            message.success('Update is successful!');
            runInAction(() => {
                this.setIsLoading(false);
                this.isDirty = false;
            });
        } catch (e) {
            console.error('Error', e);
            runInAction(() => this.setIsLoading(false));
        }
    }

    public collapseAll() {
        this.collapsedClasses = this.data.reduce((acc, tClass) => {
            acc[tClass.name] = true;
            return acc;
        }, {});
    }

    public toggleCollapseExpand(className: string) {
        this.collapsedClasses = {
            ...this.collapsedClasses,
            [className]: !this.collapsedClasses[className]
        };
    }

    get isAllCollapsed() {
        return Object.values(this.collapsedClasses).every(value => value);
    }

    // Traffic class actions
    public addClass(className: string, classDescription: string) {
        const newClass = {
            name: className,
            description: classDescription,
            index: this.data.length,
            rules: []
        };
        this.data = [...this.data, newClass];
        this.markDirty();
        this.collapsedClasses = {...this.collapsedClasses, [className]: false};
    }

    public editClass(trafficClass: TrafficClass, newClassName: string, classDescription: string) {
        this.updateTrafficClass(trafficClass, {name: newClassName, description: classDescription});
        this.markDirty();
    }

    public removeClass(trafficClass: TrafficClass) {
        this.data = [...this.data.filter(tClass => tClass.index !== trafficClass.index).map((tClass, index) => ({
            ...tClass,
            index
        }))];
        this.markDirty();
    }

    public reorderClasses(startIndex: number, endIndex: number) {
        const newClassesArray = this.reorderArray(this.data, startIndex, endIndex);
        this.data = this.updateClassesIndex(newClassesArray);
        this.markDirty();
    }

    // Rule actions
    public addRulesToTrafficClass(tClass: TrafficClass, itemsToAdd: { destination: string, port: string }[]) {
        const rulesToAdd = this.createRulesFromValues(itemsToAdd, tClass.rules.length);
        this.updateTrafficClassRules(tClass, rules => [...rules, ...rulesToAdd]);
    }

    public editRule(tClass: TrafficClass, changedRule: TrafficClassRule, newValues: { destination: string, port: string }) {
        this.updateTrafficClassRules(tClass, rules => rules.map(rule => rule.index === changedRule.index ? { ...rule, port: newValues.port, ...this.parseTypeByValue(newValues.destination) } : rule));
    }

    public removeRuleAndReorder(trafficClass: TrafficClass, ruleIndex: number) {
        this.updateTrafficClassRules(trafficClass, rules => rules.filter(rule => rule.index !== ruleIndex));
    }

    public reorderRules(trafficClass: TrafficClass, startIndex: number, endIndex: number) {
        this.updateTrafficClassRules(trafficClass, rules => this.reorderArray(rules, startIndex, endIndex));
    }

    // Helper methods
    private updateTrafficClass(trafficClass: TrafficClass, updates: Partial<TrafficClass>) {
        this.data = this.data.map(tClass => tClass.index === trafficClass.index ? { ...tClass, ...updates } : tClass);
    }

    private updateTrafficClassRules(trafficClass: TrafficClass, updateFn: (rules: TrafficClassRule[]) => TrafficClassRule[]) {
        this.data = this.data.map(tClass => tClass.index === trafficClass.index ? { ...tClass, rules: this.updateRules(updateFn(tClass.rules)) } : tClass);
        this.markDirty();
    }

    private updateRules(rules: TrafficClassRule[]) {
        return rules.map((rule, index) => ({ ...rule, index }));
    }

    private updateClassesIndex(classes: TrafficClass[]) {
        return classes.map((cls, index) => ({ ...cls, index }));
    }

    private reorderArray<T>(array: T[], startIndex: number, endIndex: number): T[] {
        const result = Array.from(array);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    private createRulesFromValues(itemsToAdd: { destination: string, port: string }[], index: number) {
        return itemsToAdd.map(item => ({
            index,
            port: item.port || '*',
            ...this.parseTypeByValue(item.destination)
        }));
    }

    private parseTypeByValue(value: string) {
        if (isValidDomain(value, { wildcard: true })) {
            return this.parseUrlType(value);
        } else if (validateIP(value)) {
            return this.parseIpType(value);
        } else {
            return { type: 'unknown' };
        }
    }

    private parseIpType(value: string) {
        let type: TrafficClassRuleType;
        if (value.endsWith('*')) {
            type = TrafficClassRuleType.IP_ADDRESS_PREFIX_AND_PORT;
        } else if (value.includes('/')) {
            type = TrafficClassRuleType.IP_ADDRESS_RANGE_AND_PORT;
        } else {
            type = TrafficClassRuleType.IP_ADDRESS_AND_PORT;
        }

        return { type, ip: value };
    }

    private parseUrlType(value: string) {
        let type: TrafficClassRuleType;
        if (value.startsWith('*')) {
            type = TrafficClassRuleType.DNS_PREFIX_AND_PORT;
        } else {
            type = TrafficClassRuleType.DNS_FQDN_AND_PORT;
        }
        return { type, dns: value };
    }

    private initializeData(trafficClassesValues: TrafficClass[]) {
        if (trafficClassesValues.length === 0) {
            return this.initDataWithDefault();
        }
        return this.sortAllClassesAndRules(trafficClassesValues);
    }

    private sortAllClassesAndRules(trafficClassesValues: TrafficClass[]) {
        const sortedTrafficClasses = trafficClassesValues.slice().sort((a, b) => a.index - b.index);
        return sortedTrafficClasses.map(trafficClass => ({
            ...trafficClass,
            rules: trafficClass.rules.slice().sort((a, b) => a.index - b.index)
        }));
    }

    private initDataWithDefault() {
        return Object.values(DefaultTrafficClasses).map((defaultClass, index) => ({
            name: defaultClass,
            description: TRAFFIC_CLASSES_DESC[defaultClass],
            index,
            rules: []
        }));
    }

    private markDirty() {
        if (!this.isDirty) {
            this.isDirty = true;
        }
    }
}
