import React, { useEffect, useState } from 'react';
import { useStores } from '../../../store';
import TrendChart from '../trend-chart/trend-chart';
import { observer } from 'mobx-react-lite';
import widgetsService from '../../../../services/widgets.service';
import { convertPointsToAxis, fillMissingDates, formatNumber } from '../../../../utils/parse';

interface TrendChartContainerProps {
    widgetName: string;
    desc: string;
    positiveTrend: boolean;
}

const TrendChartContainer: React.FC<TrendChartContainerProps> = observer(({ widgetName, desc, positiveTrend }) => {
    const { customerStore, dashboardStore } = useStores();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<{
        title: string;
        percent: number;
        trendDirection: 'up' | 'down';
        trendColor: 'green' | 'red';
        points: { x: number; y: number }[];
    } | null>(null);

    const CHART_HEIGHT = 70;

    useEffect(() => {
        setIsLoading(true);
        if (customerStore.selectedCustomer) {
            widgetsService.getWidgetData(
                customerStore.selectedCustomer.name,
                widgetName,
                dashboardStore.dateRangeObj
            )
                .then((res) => {
                    const response = res[0];
                    const filledData = fillMissingDates(response.dataList, dashboardStore.dateRangeObj);
                    const trendChartData = {
                        title: response.totalCount || 0,
                        percent: response.percentChange || 0,
                        trendDirection: response.trend,
                        points: convertPointsToAxis(filledData, CHART_HEIGHT) ,
                        trendColor: (positiveTrend && response.trend === 'up') || (!positiveTrend && response.trend === 'down')
                            ? "green" : "red" as 'green' | 'red'
                    };
                    setData(trendChartData);
                    setIsLoading(false);
                })
                .catch(() => setIsLoading(false));
        }
    }, [dashboardStore.dateRangeObj, customerStore.selectedCustomer]);

    return (
        <TrendChart
            isLoading={isLoading}
            title={formatNumber(data?.title)}
            desc={desc}
            percent={data?.percent}
            trendDirection={data?.trendDirection}
            trendColor={data?.trendColor}
            points={data?.points}
            height={CHART_HEIGHT}
        />
    );
});

export default TrendChartContainer;
