import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import SecurityIssueImportance from './security-issue-importance/security-issue-importance';
import IssueCategories from './issue-categories/issue-categories';
import { ReactComponent as SyncIcon } from '../../../assets/svg/sync.svg';
import { ReactComponent as InfoIcon } from '../../../assets/svg/information-circle_96.svg';
import { ReactComponent as DocIcon} from '../../../assets/svg/file_96.svg';
import { ReactComponent as GridIcon } from '../../../assets/svg/grid_96.svg';

import './security-dashboard.scss';
import HelpItem from './help-item/help-item';
import { HELP_INFO_DESC } from './security-dashboard.constant';
import Footer from './footer/footer';
import SecurityIssuesTables from './security-issues-tables/security-issues-tables';
import RecommendedActionList from './recommended-action-list/recommended-action-list';
import RangePicker from '../../shared/range-picker/range.picker';
import { useStores } from '../../store';
import TrendChartContainer from './trend-chart-container/trend-chart-container';
import { WIDGET_NAME_MAP } from '../../../utils/widget-map';

export const SecurityDashboard = observer(() => {

    const { dashboardStore } = useStores();

    const refresh = () => {
        dashboardStore.setDateRangeObj(dashboardStore.selectedRangeIndex);
    };

    const handleMenuClick = ({ key }: { key: string }) => {
        dashboardStore.setSelectedRangeIndex(Number(key));
    };

    useEffect(() => {
        dashboardStore.setDateRangeObj(dashboardStore.selectedRangeIndex);
    }, [dashboardStore.selectedRangeIndex]);

    return <div className="security-dashboard">
        <div className="security-dashboard-header">
            <div className="security-dashboard-header-title">Security dashboard</div>
            <div className="security-dashboard-header-action">
                <div className="security-dashboard-date-filter">
                    <RangePicker
                        selectedRangeIndex={dashboardStore.selectedRangeIndex}
                        handleMenuClick={handleMenuClick}
                    />
                </div>
                <button className="security-dashboard-refresh" onClick={refresh}>
                    <SyncIcon />
                </button>
            </div>
        </div>
        <div className="security-dashboard-content">
            <div className="security-dashboard-top row">
                <div className="box">
                    <TrendChartContainer positiveTrend={true}
                                         widgetName={WIDGET_NAME_MAP.TOTAL_PROTECTED_DEVICES}
                                         desc="Total monitored devices"/>
                </div>
                <div className="box">
                    <TrendChartContainer positiveTrend={false}
                                         widgetName={WIDGET_NAME_MAP.DEVICES_AT_RISK}
                                         desc="Devices at risk" />
                </div>
                <div className="box">
                    <TrendChartContainer positiveTrend={false}
                                         widgetName={WIDGET_NAME_MAP.TOTAL_ISSUES}
                                         desc="Total security issues" />
                </div>
            </div>
            <div className="security-dashboard-middle row">
                <div className="box">
                    <SecurityIssueImportance />
                </div>
                <div className="box">
                    <IssueCategories />
                </div>
            </div>
            <div className="security-dashboard-bottom row">
                <div className="box"><SecurityIssuesTables /></div>
                <div className="box"><RecommendedActionList /></div>
            </div>
            <div className="security-dashboard-help-area row">
                <div className="box">
                    <HelpItem
                        title="Help center"
                        description={HELP_INFO_DESC}
                        IconComponent={InfoIcon}
                    />
                </div>
                <div className="box">
                    <HelpItem
                        title="Documentation"
                        description={HELP_INFO_DESC}
                        IconComponent={DocIcon}
                    />
                </div>
                <div className="box">
                    <HelpItem
                        title="Tutorials"
                        description={HELP_INFO_DESC}
                        IconComponent={GridIcon}
                    />
                </div>
            </div>
        </div>
        <div className="security-dashboard-footer">
            <Footer />
        </div>
    </div>;
});