import './anomalies-filter.scss';
import { observer } from 'mobx-react-lite';
import DateRangePickerComponent from '../../../shared/date-range-picker/date-range-picker';
import { Select } from 'antd';
import { SidebarButtons } from '../../../shared/sidebar/sidebar-buttons';
import React, { useEffect, useMemo, useState } from 'react';
import { useStores } from '../../../store';
import { UIAnomaliesFilter } from '../../../../dtos/anomalies.dto';
const { Option } = Select;

export interface AnomaliesFilterProps {
    onClose: () => void;
    showApnFilter: boolean;
    showRegionFilter: boolean;
}

export const AnomaliesFilter = observer(({onClose, showApnFilter, showRegionFilter}: AnomaliesFilterProps) => {
    const [temporaryFilter, setTemporaryFilter] = useState<UIAnomaliesFilter>();
    const {anomaliesStore, useCaseStore, customerStore} = useStores();

    useEffect(() => {
        if (anomaliesStore.worldRegions.length === 0) {
            anomaliesStore.initWorldRegions();
        }
    }, []);

    useEffect(() => {
        if (customerStore.selectedCustomer) {
            setTemporaryFilter({...anomaliesStore.filter});
        }
    }, [customerStore.selectedCustomer, anomaliesStore.filter]);

    const apnOptions = useMemo(() => {
        if (useCaseStore.currentUseCaseValues?.preferences.apnPreferences) {
            return useCaseStore.currentUseCaseValues.preferences.apnPreferences.map(apn => ({
                label: apn.apnName,
                value: apn.apnName,
            }));
        } else {
            return [];
        }
    }, [useCaseStore.currentUseCaseValues?.preferences.apnPreferences]);

    const handleDateRangeChange = (selectedOption, range) => {
        setTemporaryFilter({
            ...temporaryFilter,
            'dateRange': range ?? selectedOption,
        });
    };

    const handleApnChange = (value) => {
        setTemporaryFilter({
            ...temporaryFilter,
            'apn': value,
        });
    };

    const handleRegionChange = (value) => {
        setTemporaryFilter({
            ...temporaryFilter,
            'worldRegion': value,
        });
    };

    const handleRatTypeChange = (value) => {
        setTemporaryFilter({
            ...temporaryFilter,
            'ratType': value,
        });
    };

    function onFilterApply() {
        anomaliesStore.setFilter(temporaryFilter);
        onClose();
    }

    function applyDisabled() {
        return !temporaryFilter?.dateRange;
    }

    return (<div className='anomalies-filter-container'>
        <div className='anomalies-filter'>
            <div className='filter-title'>Filters</div>
            <div className='filter-subtitle'>Date Range</div>
            <div className='filter-date-range'>
                <DateRangePickerComponent
                    defaultValue={temporaryFilter?.dateRange}
                    onDateRangeChange={handleDateRangeChange}
                    isWeekOnly={useCaseStore.currentUseCaseName === 'software_version'}
                    dayOffsetFilter={useCaseStore.useCaseDayOffsetFilter}
                />
            </div>
            {showApnFilter && <>
                <div className='filter-subtitle'>APN</div>
                <div className='apn-select'>
                    <Select className={'anomalies-filter-list'}
                            size={"large"}
                            popupMatchSelectWidth={410}
                            popupClassName={'anomalies-apn-dropdown'}
                            style={{width: '100%'}}
                            options={apnOptions}
                            value={temporaryFilter?.apn}
                            onChange={handleApnChange}
                            placeholder="APN"/>
                </div>
            </>}
            {showRegionFilter && <>
                <div className='filter-subtitle'>Region</div>
                <div className='world-region-select'>
                    <Select className={'anomalies-filter-list'}
                            size={"large"}
                            popupClassName={'anomalies-filter-dropdown'}
                            style={{width: '100%'}}
                            value={temporaryFilter?.worldRegion}
                            onChange={handleRegionChange}
                            placeholder="Region">
                        {anomaliesStore.worldRegions.map(worldRegion => (
                            <Option key={worldRegion.name} value={worldRegion.name}>{worldRegion.displayName}</Option>
                        ))}
                    </Select>
                </div>
            </>}
            {useCaseStore.getRatTypeSupportedForCurrentUseCase().length > 1 && <>
                <div className='filter-subtitle'>Core Type</div>
                <div className='rat-type-select'>
                    <Select className={'anomalies-filter-list'}
                            size={"large"}
                            popupClassName={'anomalies-filter-dropdown'}
                            style={{width: '100%'}}
                            value={temporaryFilter?.ratType || useCaseStore.getRatTypeSupportedForCurrentUseCase()?.[0]}
                            onChange={handleRatTypeChange}
                            placeholder="Core Type">
                        {useCaseStore.getRatTypeSupportedForCurrentUseCase().map(ratType => (
                            <Option key={ratType} value={ratType}>{ratType}</Option>
                        ))}
                    </Select>
                </div>
            </>}
        </div>
        <div className='apply-btn'><SidebarButtons applyButtonText='Apply' onApply={onFilterApply}
                                                   applyDisabled={applyDisabled()}/></div>
    </div>);
});
