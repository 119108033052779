import { Report } from '../dtos/report.dto';
import { Alert } from '../dtos/alert.dto';

export type InsightData = Report | Alert;

export interface Action {
    id: string;
    recommended_action: string;
    last_date? : string;
    desc?: string;
    severity?: string;
}

export interface TopCategoriesIssueDetail{
    total_issues: number,
    high_importance: number,
    table_data: {
        [key in Category]?: {
            total_issues: number;
            rows: IssueDetail[];
        };
    };
}

export interface IssueDetail {
    id: string;
    name: string;
    long_description: string;
    last_date: string;
    severity: Severity;
    affected_devices: number;
    recommended_action: string;
    distinct_apns: string[];
    percentChange: number;
    trend: string;
    devices_date_points: any[];
    category_title: Category;
    use_case_name: string;
}

export const CATEGORY_VALUES = ['Attack', 'Threat', 'Weakness', 'Anomaly'];

export type Category = typeof CATEGORY_VALUES[number];

export const SEVERITY_VALUES = ['low', 'medium', 'high'];

export type Severity = typeof SEVERITY_VALUES[number];

export interface SecurityIssue {
    severity: Severity;
    count: number;
    affectedDevices?: number;
}

export interface TimeRange { from: number; to: number }

export interface DateData {
    date: string;
    count: number;
}