import React, { useRef, useEffect } from 'react';

export enum ColorType {
    PURPLE = 'purple',
    BLUE = 'blue',
    RED = 'red',
    ORANGE = 'orange',
    GREEN = 'green',
    GREY = 'grey'
}

interface LineChartProps {
    points: { x: number; y: number }[];
    colorType: ColorType;
    width?: number;
    height?: number;
}

const LineChart: React.FC<LineChartProps> = ({ points, colorType, width = 140, height = 80 }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const colorPairs = {
        [ColorType.PURPLE]: {
            lineColor: '#6610F2',
            backgroundColor: 'linear-gradient(180deg, rgba(101, 28, 223, 0.59) 0%, rgba(114, 47, 226, 0) 100%)',
        },
        [ColorType.BLUE]: {
            lineColor: '#009FDB',
            backgroundColor: 'linear-gradient(180deg, rgba(22, 93, 255, 0.2) 0%, rgba(22, 93, 255, 0) 100%)',
        },
        [ColorType.RED]: {
            lineColor: '#EE545C',
            backgroundColor: 'linear-gradient(180deg, rgba(238, 84, 92, 0.59) 0%, rgba(238, 84, 92, 0) 100%)',
        },
        [ColorType.ORANGE]: {
            lineColor: '#DA6A2F',
            backgroundColor: 'linear-gradient(180deg, rgba(218, 106, 47, 0.59) 0%, rgba(218, 106, 47, 0) 100%)',
        },
        [ColorType.GREY]: {
            lineColor: '#A9A9A9',
            backgroundColor: 'linear-gradient(180deg, rgba(169, 169, 169, 0.59) 0%, rgba(169, 169, 169, 0) 100%)',
        },
        [ColorType.GREEN]: {
            lineColor: '#28A745',
            backgroundColor: 'linear-gradient(180deg, rgba(40, 167, 69, 0.59) 0%, rgba(40, 167, 69, 0) 100%)',
        },
    };

    const selectedColors = colorPairs[colorType] || colorPairs[ColorType.PURPLE];

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const width = canvas.width;
        const height = canvas.height;

        // Clear the canvas
        ctx.clearRect(0, 0, width, height);

        const gradient = ctx.createLinearGradient(0, 0, 0, height);
        const colorStops = selectedColors.backgroundColor.match(/rgba?\([^)]+\)/g);
        colorStops.forEach((color, index) => {
            const position = index / (colorStops.length - 1);
            gradient.addColorStop(position, color);
        });

        // Draw the background with gradient
        ctx.fillStyle = gradient;
        ctx.beginPath();
        ctx.moveTo(0, height);
        points.forEach((point, index) => {
            const x = (point.x / 100) * width;
            const y = height - (point.y / 100) * height;
            if (index === 0 || index === points.length - 1) {
                ctx.lineTo(x, y);
            } else {
                const prevX = (points[index - 1].x / 100) * width;
                const prevY = height - (points[index - 1].y / 100) * height;
                const midY = (y + prevY) / 2;
                const midX = (x + prevX) / 2;
                ctx.quadraticCurveTo(prevX, prevY, midX, midY);
            }
        });
        ctx.lineTo(width, height);
        ctx.closePath();
        ctx.fill();

        // Draw the line
        ctx.strokeStyle = selectedColors.lineColor;
        ctx.lineWidth = 2;
        ctx.lineJoin = 'round';
        ctx.lineCap = 'round';
        ctx.beginPath();
        ctx.moveTo((points[0].x / 100) * width, height - (points[0].y / 100) * height);

        for (let i = 1; i < points.length; i++) {
            const x = (points[i].x / 100) * width;
            const y = height - (points[i].y / 100) * height;
            const prevX = (points[i - 1].x / 100) * width;
            const prevY = height - (points[i - 1].y / 100) * height;
            const midX = (x + prevX) / 2;
            const midY = (y + prevY) / 2;
            ctx.quadraticCurveTo(prevX, prevY, midX, midY);
        }
        ctx.lineTo(width, height - (points[points.length - 1].y / 100) * height);
        ctx.stroke();
    }, [points, selectedColors]);

    return <canvas ref={canvasRef} width={width} height={height} style={{height: height, width: width}} />;
};

export default LineChart;