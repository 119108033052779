import { observer } from 'mobx-react-lite';
import CustomModal from '../../../../shared/custom-modal/custom-modal';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Input } from 'antd';
import { TrafficClass } from '../../../../../dtos/traffic-classes.dto';
import { ReactComponent as PencilIcon } from '../../../../../assets/svg/pencil.svg';
import { useStores } from '../../../../store';
import './add-edit-class.scss';
import TextArea from 'antd/es/input/TextArea';

interface AddEditClassProps {
    mode: 'add' | 'edit';
    trafficClass?: TrafficClass;
}

const AddEditClass = observer<AddEditClassProps>(({mode, trafficClass}) => {

    const classNameAllowedChars = "^[a-zA-Z0-9 ._-]+$";

    const { trafficClassStore } = useStores();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [className, setClassName] = useState<string>();
    const [classDescription, setClassDescription] = useState<string>();

    useEffect(() => {
        if (trafficClass) {
            setClassName(trafficClass.name);
            setClassDescription(trafficClass.description);
        }
    }, [trafficClass]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setClassName(null);
        setClassDescription(null);
    };

    const saveClass = () => {
        if (mode === 'add') {
            trafficClassStore.addClass(className.trim(), classDescription);
        } else if (mode === 'edit') {
            trafficClassStore.editClass(trafficClass, className.trim(), classDescription);
        }
        closeModal();
    };

    const isDuplicate = useMemo(() =>
        trafficClassStore.data.some(tc =>
            tc.name.trim().toLowerCase() === className?.trim().toLowerCase() &&
            tc.index !== trafficClass?.index)
    , [className]);

    const isValidName = useMemo(() => {
        return new RegExp(classNameAllowedChars).test(className);
    }, [className]);

    return (<>
        {mode === 'add' && <Button className="add-class secondary-btn" type="primary"
                                   onClick={openModal}>Add class</Button>}
        {mode === 'edit' && <Button className='edit-class' onClick={openModal}>
            <PencilIcon />
        </Button>}
        <CustomModal
            isOpen={isModalOpen}
            width="540px"
            title={mode === 'add' ? 'Add new class' : 'Edit class'}
            customClassName={'add-edit-class'}
            okText={mode === 'add' ? "Add" : "Apply"}
            onOk={saveClass}
            okButtonProps={{ disabled: !className?.trim().length || !classDescription || isDuplicate || !isValidName}}
            cancelButtonProps={{className: 'secondary-btn'}}
            onCancel={closeModal}>
            <>
                <div className="textarea-wrapper">
                    <div className="select-label">Class name</div>
                    <Input
                        className={`input class-name-input`}
                        placeholder={'Enter class name'}
                        value={className}
                        maxLength={50}
                        onChange={(e) => setClassName(e.target.value)}
                    />
                </div>
                <div className="textarea-wrapper">
                    <div className="select-label">Class description</div>
                    <TextArea
                        size={"large"}
                        className={`textarea-input class-description-input`}
                        placeholder={'Enter description here...'}
                        value={classDescription}
                        maxLength={250}
                        onChange={(e) => setClassDescription(e.target.value)}
                    />
                </div>

                {isDuplicate && <div className="error-message">Duplicate class name</div>}
                {!isValidName && <div className="error-message">Invalid class name</div>}
            </>
        </CustomModal>
    </>);
});

export default AddEditClass;