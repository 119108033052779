import { BaseStore } from './base.store';
import { RootStore } from './index';
import { action, computed, makeObservable, observable } from 'mobx';
import { MenuOptions } from '../../utils/enums';


export class UXStore extends BaseStore {
    public selectedMenuItem: MenuOptions;
    public uniqueId = Date.now().toString();

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            selectedMenuItem: observable,
            setSelectedMenuItem: action,
            showLoader: computed,
            getUniqueId: computed,
        });
    }

    public setSelectedMenuItem(selectedMenuItem: MenuOptions) {
        this.selectedMenuItem = selectedMenuItem;
    }

    get showLoader(): boolean {
        return this.rootStore.userStore.isLoading || this.rootStore.useCaseStore.isLoading || this.rootStore.insightsStore.isLoading ||
            this.rootStore.customerStore.isLoading || this.rootStore.exceptionListStore.isLoading || this.rootStore.dashboardStore.isLoading;
    }

    get getUniqueId(): string {
        return this.uniqueId;
    }
}
