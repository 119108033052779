import React, { useEffect, useState } from 'react';
import './recommended-action-list.scss';
import RecommendedAction from '../recommended-action/recommended-action';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import widgetsService from '../../../../services/widgets.service';
import { WIDGET_NAME_MAP } from '../../../../utils/widget-map';
import LoadingWrapper from '../../../shared/loading-wrapper/loading-wrapper';
import { v4 as uuidv4 } from 'uuid';

const RecommendedActionList: React.FC = observer(() => {

    const { dashboardStore, customerStore } = useStores();

    const [actions, setActions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if (customerStore.selectedCustomer) {
            widgetsService.getWidgetData(customerStore.selectedCustomer.name,
                WIDGET_NAME_MAP.RECOMMENDED_ACTIONS,
                dashboardStore.dateRangeObj
            ).then((res) => {
                setActions(res);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
        }
    }, [dashboardStore.dateRangeObj, customerStore.selectedCustomer]);

    return (
        <div className="recommended-action-list">
            <div className="recommended-action-title">
                Recommended Actions
            </div>
            <div className="recommended-action-items">
                <LoadingWrapper isLoading={isLoading} skeletonProps={{ count: 10, height: 65, style: { marginBottom: 15 } }}>
                    {actions.map(action => (
                        <RecommendedAction key={uuidv4()} action={action}/>
                    ))}
                </LoadingWrapper>
            </div>
        </div>
    );
});

export default RecommendedActionList;
