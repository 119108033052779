import React, {useEffect, useState } from 'react';
import './header.scss';
import { ReactComponent as MTADLogo } from '../../assets/svg/logo-att.svg';
import { Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useStores } from '../store';
import StatusIndicator from '../app-content/status-indicator/status-indicator';
import CustomModal from '../shared/custom-modal/custom-modal';
import { useNavigate } from 'react-router';
import { convertInitials } from '../../utils/parse';
import { SideMenu } from '../side-menu/side-menu';

export const Header = observer(() => {
    const { customerStore, useCaseStore, uxStore, userStore } = useStores();

    const navigate = useNavigate();

    const [userInitials, setUserInitials] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
    const [clickedVal, setClickedVal] = useState<string | null>(null);

    useEffect(() => {
        setUserInitials(convertInitials(userStore.data.displayName));
    }, [userStore.data]);

    useEffect(() => {
        setCustomerList(customerStore.data.map(item => ({
            value: item.name,
            label: item.label
        })));
    } ,[customerStore.data]);

    const onChange = (value) => {
        if (useCaseStore.isDirty) {
            setClickedVal(value);
            setIsDiscardModalOpen(true);
        } else {
            useCaseStore.setIsLoading(true);
            customerStore.setSelectedCustomer(value);
            navigate(`${value}/${uxStore.selectedMenuItem}`);
        }
    };

    const handleOk = () => {
        useCaseStore.setIsLoading(true);
        customerStore.setSelectedCustomer(clickedVal);
        handleCancel();
        navigate(`${clickedVal}/${uxStore.selectedMenuItem}`);
    };

    const handleCancel = () => {
        setClickedVal(customerStore.selectedCustomer.name);
        setIsDiscardModalOpen(false);
    };

    const renderDiscardChangeModal = () => (
        <CustomModal
            isOpen={isDiscardModalOpen}
            width={500}
            title="Warning"
            onOk={handleOk}
            onCancel={handleCancel}
            okText="OK"
            cancelButtonProps={{className: 'secondary-btn'}}>
            If you switch to another customer, all unsaved changed will be lost
        </CustomModal>
    );

    const renderCustomerSelection = () => (
        customerList.length === 1
            ? <span className="single-customer-label">{customerStore.selectedCustomer.label}</span>
            : <Select
                popupClassName="dropdown-dark customer-list-dropdown"
                onChange={onChange}
                className="dropdown-dark-btn customer-list-btn"
                size="large"
                value={customerStore.selectedCustomer.name}
                options={customerList}
                showSearch={true}
                listHeight={500}
            />
    );

    return (
        <div data-html2canvas-ignore="true" className="header">
            <div className="left">
                <div className="logo">
                    <MTADLogo />
                </div>
                <div className="title">MTAD {customerStore.selectedCustomer?.label}</div>
                <SideMenu/>
            </div>
            <div className="right">
                {!!customerList.length &&
                    <>
                        { renderCustomerSelection() }
                        <StatusIndicator isActive={customerStore.selectedCustomer.isActive} />
                    </>
                }
                <div className="user">
                    <div className="user-circle">{userInitials}</div>
                </div>
            </div>

            {isDiscardModalOpen && renderDiscardChangeModal()}
        </div>
    );
});
