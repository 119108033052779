import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './donut-chart.scss';
import { StatusColors } from '../security-dashboard.constant';
import { SecurityIssue } from '../../../../utils/model';

interface DonutChartProps {
    data: SecurityIssue[];
    totalValue: string;
    size: number;
}

const DonutChart: React.FC<DonutChartProps> = ({ data, totalValue, size }) => {
    const ref = useRef<SVGSVGElement>(null);

    useEffect(() => {
        const filteredData = data.filter(d => d.count > 0);
        const svg = d3.select(ref.current);

        svg.selectAll('*').remove();

        const radius = size / 2;

        svg.attr('width', size).attr('height', size);

        const defs = svg.append('defs');

        // Define gradients based on StatusColors
        Object.keys(StatusColors).forEach(status => {
            const gradient = defs.append('linearGradient')
                .attr('id', StatusColors[status].gradientId)
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '100%');

            gradient.append('stop')
                .attr('offset', '16.54%')
                .attr('stop-color', StatusColors[status].color);

            gradient.append('stop')
                .attr('offset', '88.26%')
                .attr('stop-color', StatusColors[status].endColor);
        });

        const g = svg.append('g')
            .attr('transform', `translate(${size / 2},${size / 2})`);

        const pie = d3.pie()
            .value(d => d.count)
            .sort(null)
            .padAngle(0.01);

        // Determine arc width based on size
        const arcWidth = size === 280 ? 40 : 25;

        const arc = d3.arc()
            .innerRadius(radius - arcWidth)
            .outerRadius(radius)
            .cornerRadius(5);

        const arcHover = d3.arc()
            .innerRadius(radius - arcWidth - 20)
            .outerRadius(radius)
            .cornerRadius(5);

        const tooltip = d3.select('body').append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0);

        const arcs = g.selectAll('.arc')
            .data(pie(filteredData))
            .enter()
            .append('g')
            .attr('class', 'arc')
            .on('mouseover', function(event, d) {
                d3.select(this).select('path')
                    .transition()
                    .duration(200)
                    .attr('d', arcHover);

                tooltip.transition()
                    .duration(200)
                    .style('opacity', .9);

                tooltip.html(`
                    <div class="tooltip-title">${d.data.severity}<div class="tooltip-title-count" style="color:${StatusColors[d.data.severity].color};">${d.data.count}</div></div>
                    <div class="tooltip-devices" style="color:${StatusColors[d.data.severity].color};">Impacting ${d.data.affectedDevices || 0} devices</div>
                `)
                    .style('left', (event.pageX + 10) + 'px')
                    .style('top', (event.pageY - 28) + 'px');
            })
            .on('mouseout', function(event, d) {
                d3.select(this).select('path')
                    .transition()
                    .duration(200)
                    .attr('d', arc);

                tooltip.transition()
                    .duration(500)
                    .style('opacity', 0);
            });

        if (filteredData.length > 1) {
            arcs.append('path')
                .attr('d', arc)
                .attr('fill', d => `url(#${StatusColors[d.data.severity].gradientId})`);

            arcs.append('text')
                .attr('transform', d => `translate(${arc.centroid(d)})`)
                .attr('text-anchor', 'middle')
                .attr('dy', '.35em')
                .text(d => d.data.count)
                .attr('fill', 'white')
                .attr('font-size', '18px')
                .attr('font-family', 'ATTAleckSansMd');
        } else if (filteredData.length === 1) {
            arcs.append('path')
                .attr('d', d3.arc()
                    .innerRadius(radius - arcWidth)
                    .outerRadius(radius)
                    .startAngle(0)
                    .endAngle(2 * Math.PI))
                .attr('fill', `url(#${StatusColors[filteredData[0].severity].gradientId})`);
        }

        g.append('text')
            .attr('text-anchor', 'middle')
            .attr('font-size', '46px')
            .attr('font-family', 'ATTAleckSansMd')
            .attr('fill', 'white')
            .text(totalValue);

        g.append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '1.5em')
            .attr('font-size', '18px')
            .attr('fill', 'white')
            .text('Total');

    }, [data, totalValue, size]);

    return <svg ref={ref}></svg>;
};

export default DonutChart;
