import { ReactComponent as Exclamation } from '../../../assets/svg/exclamation-circle_96.svg';
import { ReactComponent as HighMeter } from '../../../assets/svg/high-meter_96.svg';
import { ReactComponent as CorruptedNetwork } from '../../../assets/svg/corrupted-network_96.svg';
import { Severity } from '../../../utils/model';

export const StatusColors: Record<Severity, { color: string; endColor: string; gradientId: string }> = {
    high: {
        color: '#C24249',
        endColor: '#962329',
        gradientId: 'gradientHigh'
    },
    medium: {
        color: '#EA712F',
        endColor: 'rgba(234, 113, 47, 0.2)',
        gradientId: 'gradientMedium'
    },
    low: {
        color: '#848AFF',
        endColor: 'rgba(102, 16, 242, 0.2)',
        gradientId: 'gradientLow'
    },
};


export const CATEGORIES_CONFIG = [
    { fieldName: 'Attack', displayName: 'Attacks', color: '#CD11EB', gradientId: 'gradient-attack', icon: CorruptedNetwork },
    { fieldName: 'Threat', displayName: 'Threats', color: '#0149D9', gradientId: 'gradient-threat', icon: Exclamation },
    { fieldName: 'Weakness', displayName: 'Weaknesses', color: '#009FDB', gradientId: 'gradient-weakness', icon: HighMeter },
    { fieldName: 'Anomaly', displayName: 'Anomalies', color: '#11EBCA', gradientId: 'gradient-anomaly', icon: CorruptedNetwork },
];

export const HELP_INFO_DESC = 'Tools and resources to help you build applications using our self-service APIs for your language of choice.';

export const FOOTER_TEXT = '© 2023 AT&T Intellectual Property. All rights reserved. AT&T, the AT&T logo and all other AT&T marks contained herein are trademarks of AT&T intellectual property and/or AT&T affiliated companies. All other marks are the property of their respective owners.';